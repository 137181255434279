/**
 * Class used to update the header section of app based on env.
 */
class HeaderSection {
    constructor(options) {
      this.navbarBrandSelector = options.navbarBrandSelector;
      this.navbarSelector = options.navbarSelector;
      this.titleSelector = options.titleSelector;
    }

    init() {
      this.updateTitle();
      this.updateNavBarColor();
      this.updateBrand();
    }

    updateTitle() {
        const title = this.isProductionEnv() ? "MM" : "MM " + gon.env
        $(this.titleSelector).html(title);
    }

    updateNavBarColor() {
        const bg = this.isProductionEnv() ? "bg-light" : "bg-non-production"
        $(this.navbarSelector).addClass(bg)
    }

    updateBrand() {
        const brand = this.isProductionEnv() ? "MM" : "MM " + gon.env
        $(this.navbarBrandSelector).html(brand);
    }

    isProductionEnv() {
        return gon.env.toLowerCase() === "production";
    }

}

$(function() {
    const options = {
        navbarBrandSelector: "#navbar-brand",
        navbarSelector: "#navbar",
        titleSelector: "title"
    };
    const headerSection = new HeaderSection(options)
    headerSection.init();
  })
